@use "../src/styles/partials/global" as *;
@use "../src/styles/partials/variables" as *;
@use "../src/styles/partials/mixins" as *;

body {
  background-image: url("../src/assets/images/black-design-background.png");
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;

  @include tall {
    background-repeat:repeat;
  }
}

main {
  min-height: 100vh;
  
}