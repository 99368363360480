@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.target-language-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85); 
    z-index: 1000;

    &__content {
        background-color: $color-white; 
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        width: 70%; 
        max-height: 90%;
        overflow-y: auto; 
        &::-webkit-scrollbar {
            display: none; 
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        padding: 10px;
        cursor: pointer;
        
        &:hover {
            background-color: #f0f0f0; 
        }
    }
}
