@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.saved-transcriptions {
    background: rgba( 0, 0, 0, 0.75 );
    box-shadow: 0 2px 32px 0 rgba(10, 24, 233, 0.47);
    backdrop-filter: blur( 19.5px );
    -webkit-backdrop-filter: blur( 19.5px );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    color: white;
    width: 90%;
    margin-top: 7.5rem;
    margin-bottom: 6rem;
    margin-inline: auto;

    @include tablet {
        width: 75%;
        margin-inline: auto;
    }

    &__title {
        text-align: center;
        margin-bottom: 20px;
        padding-top: 3rem;
    }

    &__list {
        list-style-type: none;
        padding: 0;
    }

    &__item {
        padding: 10px;
        border-bottom: 1px solid grey;
        padding-bottom: 1.2rem;

        &:last-child {
            border-bottom: none;
            padding-bottom: 2.2rem;
        }
    }

    .transcription-details {
        padding-inline: 1rem;
        margin-bottom: 1rem;

        &__text {
            margin-bottom: 10px;
            padding-top: 1rem;
        }

        &__arrows-icon {
            height: 1.2rem;
            margin-left: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 0.8rem;
        }

        &__translated-text {
            padding-bottom: 1rem;
        }

        &__timestamp {
            font-size: 0.9em;
            color: grey;
        }

        &__target-language {
            margin-top: 0.5rem;
            color: grey;
            margin-bottom: 1.5rem;
            font-size: 0.9rem;
        }

        &__language-toggle {
            position: relative;
            margin-left: 1rem;
            background-color: transparent;
            border: none;
            color: $color-white;
            font-size: 1rem;
            cursor: pointer;

            &--icon {
                position: absolute;
                top: 0.15rem;
                margin-left: 0.5rem;
                rotate: 270deg;
                height: 1.2rem;
            }
        }
    }

    &__message {
        text-align: center;
        padding-bottom: 3rem;
    }
}

.saved-translations {
    background: rgba( 0, 0, 0, 0.75 );
    box-shadow: 0 2px 32px 0 rgba(10, 24, 233, 0.47);
    backdrop-filter: blur( 19.5px );
    -webkit-backdrop-filter: blur( 19.5px );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    color: white;
    width: 90%;
    margin-inline: auto;
    margin-top: 7.5rem;

    @include tablet {
        width: 75%;
        margin-inline: auto;
    }

    &__title {
        text-align: center;
        margin-bottom: 20px;
        padding-top: 3rem;
    }

    &__list {
        list-style-type: none;
        padding: 0;
    }

    &__item {
        margin-bottom: 15px;
        padding: 10px;
        border-bottom: 1px solid grey;
        padding-bottom: 1.2rem;
        
        &:last-child {
            border-bottom: none;
        }
    }

    .translation-details {
        padding-inline: 1rem;

        &__original-text, &__translated-text {
            
        }

        &__translated-text {
            padding-bottom: 1rem;
        }

        &__arrows-icon {
            height: 1.2rem;
            margin-left: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 0.8rem;
        }

        &__target-language {
            margin-top: 0.5rem;
        }

        &__timestamp, &__target-language {
            font-size: 0.9em;
            color: grey;
        }
    }

    &__message {
        text-align: center;
    }
}

.translation-details__original-text,
.translation-details__translated-text,
.transcription-details__text,
.transcription-details__translated-text {
    &:hover {
        color: $color-turqoise;
        cursor: pointer;
    }
}

