$large-mobile-breakpoint: 450px;
$tablet-breakpoint: 768px; 
$desktop-breakpoint: 1280px;

$color-black: #000000;
$color-white: #FFFFFF;
$color-label: #BCBCBC;
$color-input: rgba(0, 0, 0, .6);
$color-or: #B0BAC3;
$color-cta: #7C838A;
$color-turqoise: #00FEFB;
$color-blue: #1786F9;
$color-translate: #B1B1B1;
$color-dark-gray: rgba(42, 42, 42, 0.72);
$gradient-main: linear-gradient(#058DD3, #00FEC9);
