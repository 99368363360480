@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include desktop {
        margin-top: 5vh;
    }

    &__label {
        color: $color-label;
    }

    &__input {
        height: 2.6rem;
        border-radius: 20px;
        padding-left: 1rem;
        padding-top: 0.2rem;
        margin-top: 0.7rem;
        margin-bottom: 2rem;
        border: none;

        &::placeholder {
            color: $color-input;
            font-size: 0.9rem;
        }

        &:focus {
            outline: none; 
            border: 1px solid $color-blue; 
            box-shadow: 0 0 0 1.5px $color-turqoise;
        }
    }

    &__button {
        font-size: 1rem;
        font-weight: 500;
        min-width: 13.125rem;
        width: 35%;
        height: 2.9rem;
        margin-inline: auto;
        margin-top: 4rem;
        margin-bottom: 1.5rem;
        border-radius: 30px 20px;
        border: 1px solid #000;
        background: linear-gradient(180deg, #058DD3 0%, #00FEC9 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        transition: background 0.8s ease, box-shadow 0.2s ease;
        cursor: pointer;

        @include tablet {
            margin-top: 4rem;
        }

        @include desktop {
            border: none;
        }

        &:hover {
            box-shadow: none;
            background: white;
            color: black;
            border: 1.8px solid $color-turqoise;
        }

        &--google {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 2.6rem;
            padding: 0; 
            background-image: $gradient-main;
            border: none;
            border-radius: 20px;
            font-size: 0.9rem;
            margin-top: 1rem;
            overflow: hidden; 
            cursor: pointer;
            

            &:hover {

                &::after {
                    background-color: $color-white;
                    top: 1.5px; 
                    right: 1.5px;
                    bottom: 1.5px;
                    left: 1.5px;
                }

                span {
                    background-image: linear-gradient(to right, $color-black, $color-black); // Change text gradient on hover
                }
            }
            
            &::after {
                content: '';
                position: absolute;
                top: 1px; 
                right: 1px;
                bottom: 1px;
                left: 1px;
                background-color: $color-black; 
                border-radius: 19px; 
                z-index: 1;
                transition: background-color 0.8s ease;
            }

            div {
                display: flex;
                align-items: center;
            }

            span {
                display: flex;
                align-items: center;
                width: 100%;
                position: relative;
                z-index: 2;
                background-image: $gradient-main;
                -webkit-background-clip: text;
                color: transparent;
                padding-bottom: 0.2rem;
                transition: background-image 0.8s ease;
                text-align: center;
                justify-content: center;
            }

            img { 
                padding-right: 0.75rem;
            }
        }
    }
}