@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__label {
        color: $color-label;
    }

    &__input {
        height: 2.6rem;
        border-radius: 20px;
        padding-left: 1rem;
        padding-top: 0.2rem;
        margin-top: 0.7rem;
        margin-bottom: 2rem;
        border: none;

        &::placeholder {
            color: $color-input;
            font-size: 0.9rem;
        }

        &:focus {
            outline: none; 
            border: 1px solid $color-blue; 
            box-shadow: 0 0 0 1.5px $color-turqoise;
        }
    }

    &__button {
        font-size: 1rem;
        font-weight: 500;
        min-width: 13.125rem;
        width: 35%;
        height: 2.9rem;
        margin-inline: auto;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        border-radius: 30px 20px;
        border: 1px solid #000;
        background: linear-gradient(180deg, #058DD3 0%, #00FEC9 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        transition: background 0.8s ease, box-shadow 0.2s ease;
        cursor: pointer;

        @include desktop {
            border: none;
        }

        &:hover {
            box-shadow: none;
            background: white;
            color: black;
            border: 1.8px solid $color-turqoise;
        }
    }
}