@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.upload {
    z-index: 20;
    position: relative;
    margin-top: 8rem;
    background-color: $color-white;
    border: 1px solid $color-white;
    border-radius: 20px;
    width: 80%;
    height: max-content;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4.4rem;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s, visibility 0.5s;

    @include tablet {
        width: 60%;
        margin-top: 15%;
    }

    @include desktop {
        width: 50%;
        max-width: 45rem;
        margin-top: 10%;
    }

    &__close {
        height: 1.2rem;
        position: absolute;
        right: 1.4rem;
        top: 1.1rem;
        cursor: pointer;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 4rem;

        &--icon {
            height: 2rem;
            margin-bottom: 0.7rem;
        }

        &--text {
            font-size: 1.3rem;
            margin-bottom: 3rem;
        }
    }

    &__drag {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #9F9F9F;
        border-style: dashed;
        border-radius: 10px;
        width: 75%;
        height: max-content;
        padding-top: 2rem;
        padding-bottom: 1.4rem;

        &--header {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }

        &--supported {
            font-size: 0.85rem;
            margin-bottom: 1rem;
        }

        &--filetypes {
            font-size: 0.7rem;
            text-align: center;
            width: 67%;
            color: #808080;
            margin-bottom: 1.5rem;
        }

        &--icon {
            height: 1.2rem;
            margin-bottom: 1.5rem;
        }

        &--file-btn {
            background-color: #058DD3;
            border: 1px solid #058DD3;
            border-radius: 4px;
            font-size: 0.75rem;
            font-weight: 500;
            color: $color-white;
            text-transform: uppercase;
            text-align: center;
            width: 60%;
            padding: 0.28rem 0.7rem;
            cursor: pointer;
            
            @include large-mobile {
                width: 40%;
            }
        }

        &--file-input {
            display: none;
        }
    }
}

.upload.dimmed {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s 0.5s;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10; 
}

.file-upload {

    &__result {
        background-color: $color-black;
        margin-inline: 5rem;
        border-radius: 20px;
        margin-top: 6rem;
        padding-block: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-white;

        @include tablet {
            margin-inline: 8rem;
            margin-top: 12rem;
        }

        @include desktop {
            margin-inline: 16rem;
            margin-top: 18rem;
        }

        &--header {
            color: $color-white;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        &--value {
            color: $color-white;
            text-align: left;
            padding-inline: 3rem;

            @include tablet {
                padding-inline: 3rem;
            }
        }

        &--buttons {
            margin-top: 2rem;
            margin-inline: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--save, &--new {
            border: 1px solid $color-white;
            background-color: $color-black;
            color: $color-white;
            padding: 0.5rem 1rem;
            cursor: pointer;

            &:hover {
                background-color: $color-white;
                color: $color-black;
            }
        }

        &--save {
            border-radius: 20px 0 0 20px;
            border-right: none;
        }

        &--new {
            border-radius: 0px 20px 20px 0;
            border-left: 1px solid $color-white;
        }
    }
}