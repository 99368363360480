@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.signup-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-inline: 2rem;
    padding-top: 3rem;

    @include tablet {
        margin-inline: 7rem;
        padding-top: 0;
    }

    @include desktop {
        flex-direction: row;
        margin: 0;
    }

    &__header {
        width: 100%;
        margin-top: 1rem;

        @include tablet {
            display: flex;
            width: 100%;
            display: flex;
            justify-content: center;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-block: 0;
        }

        &--container {
            display: flex;
            width: 100%;

            @include desktop {
                height: 100vh;
                width: 50%;
            }
        }

        &--box {
            display: none;

            @include tablet {
                display: block;
                border-bottom: 1px solid $color-white;
                width: 100%;
                margin-bottom: 4.5rem;
                width: 100%;
            }

            @include desktop {
                margin: 0;
                border-bottom: none;
                width: 50%;
                height: 20vh;
                border-right: 1px solid $color-white;
            }
        }
    }

    &__logo {
        display: none;
        width: 15rem;
        z-index: 100;

        @include tablet {
            display: block;
            width: 16rem;
        }

        @include desktop {
            margin-inline: auto;
        }

        &--container {
            width: min-content;
            margin-inline: auto;
            display: flex;
            justify-content: center;

            @include tablet {
                width: 11rem;
            }
        }
    }

    &__form--container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include desktop {
            background-color: $color-dark-gray;
            height: 100vh;
            padding-inline: 15%;
            padding-block: 30%;
        }
    }

    &__or {
        color: $color-or;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        text-align: center;
    }

    &__cta {
        color: $color-cta;
        margin-bottom: 2rem;
        font-size: 1rem;
        text-align: center;

        &--login {
            text-decoration: none;
            border: none;
            background: transparent;
            color: $color-turqoise;
            font-size: 0.9rem;
            font-weight: 600;
            padding-left: 0.2rem;
            cursor: pointer;
        }
    }
}

#stubborn-section {

    @include tablet {
        width: 100%;
    }

    @include desktop {
        height: 100vh;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

#stubborn-container {
    width: min-content;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    
    @include tablet {
        width: 11rem;
    }
}