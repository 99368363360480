@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.ellipsis {
    position: fixed;
    top: 2rem;
    margin-right: 2rem;
    z-index: 15;

    &__icon {
        height: 2rem;
        cursor: pointer;

        @include tablet {
            height: 3rem;
        }
    }
}

.hidden {
    display: none;
}
