* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
}

