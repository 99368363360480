@use './variables' as *;

@mixin large-mobile {
    @media (min-width: $large-mobile-breakpoint) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin tall {
    @media (min-height: 1200px) {
        @content;
    }
}